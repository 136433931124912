import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { SetPropertyFavoriteStatusRequestBody } from '../_base/generated/data-contracts';

// POST /api/property/${publishId}/favorite
export const setPropertyFavoriteStatus = (
  body: {
    publishId: string;
  } & SetPropertyFavoriteStatusRequestBody
) => {
  const { publishId, ...data } = body;

  return api.propertyControllerSetPropertyFavoriteStatus(publishId, data);
};

export const useSetPropertyFavoriteStatus = () => {
  return useMutation({ mutationFn: setPropertyFavoriteStatus });
};
