import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /api/property/${propertyId}/detail
export const getPropertyDetails = (propertyId: string, includeClosingInfo = false) => {
  return api.propertyControllerGetPropertyDetails(propertyId);
};

export const getPropertyDetailsForMe = (propertyId: string) => {
  return api.propertyControllerGetPropertyDetailsForMarketExpert(propertyId);
};

export const usePropertyDetails = (...args: Parameters<typeof getPropertyDetails>) => {
  return useQuery({ queryKey: ['getPropertyDetails'], queryFn: () => getPropertyDetails(...args) });
};
