import classNames from 'classnames';
import { isPast } from 'date-fns';
import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { PropertyStatus } from '../../api/_base/generated/data-contracts';
import { calculateOffersDue, convertToNoonDate, isTimeLeftADate } from '../../utils/dateTime.utils';
import { TimerIcon } from '../Icons';

import styles from './OfferDueCountdown.module.scss';

interface OfferDueCountdownProps {
  propertyStatus: string;
  projectedCloseDate: string;
  setPropertyStatus?: (propertyStatus: PropertyStatus) => void;
  condensed?: boolean;
  hideExtraText?: boolean;
  pillContainerClassName?: string;
  className?: string;
}

export const OfferDueCountdown = ({
  propertyStatus,
  projectedCloseDate,
  setPropertyStatus,
  condensed,
  hideExtraText,
  pillContainerClassName,
  className
}: OfferDueCountdownProps) => {
  const closeDate = useMemo(() => convertToNoonDate(projectedCloseDate), [projectedCloseDate]);
  const [timeLeft, setTimeLeft] = useState(closeDate ? calculateOffersDue(closeDate) : '');
  const showCloseTime = isTimeLeftADate(closeDate);

  const setPropertyStatusToPending = useCallback(() => {
    if (!isPast(closeDate)) return;

    if (setPropertyStatus && propertyStatus === PropertyStatus.ACTIVE) {
      setPropertyStatus(PropertyStatus.PENDING);
    }
  }, [propertyStatus, setPropertyStatus, closeDate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (closeDate) {
        setTimeLeft(calculateOffersDue(closeDate));
        setPropertyStatusToPending();
      }
    }, 1000);

    if (isPast(closeDate)) {
      clearTimeout(timer);
    }

    setPropertyStatusToPending();

    return () => clearTimeout(timer);
  }, [closeDate, setPropertyStatusToPending, timeLeft]);

  const offerDueIn = () => {
    return closeDate ? (
      <div className={styles.offerDueContainer}>
        <div
          className={classNames(pillContainerClassName, styles.pillContainer, {
            [styles.condensed]: condensed
          })}
        >
          <div>
            <TimerIcon className={styles.timerIcon} />
          </div>
          {!condensed && <div className={styles.offerDueText}>Offers due</div>}
          <div className={styles.timeLeft}>{timeLeft}</div>
          {showCloseTime && <div className={styles.closeTime}>12 PM PST</div>}
        </div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <div className={className}>
      {propertyStatus === PropertyStatus.ACTIVE && projectedCloseDate && <div>{offerDueIn()}</div>}
    </div>
  );
};
