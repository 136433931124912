import classNames from 'classnames';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import {
  ListingListWithOfferEntryApiModel,
  ListPropertiesResponseBodyItem
} from '../../../api/_base/generated/data-contracts';
import { setPropertyFavoriteStatus } from '../../../api/property/';
import { recordUserFavoritedProperty } from '../../../services/posthog';
import {
  recordPropertySaved,
  recordPropertyUnsaved
} from '../../../services/realTimeEvents/recordPropertyEvent';
import { NotLikedIcon } from '../../Icons';

import styles from './MakeFavoriteHeartView.module.scss';

interface MakeFavoriteHeartViewProps {
  property: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel;
  onFavorite?: (
    p: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel,
    f: boolean
  ) => void;
  containerClassName?: string;
  lovedIconClassName?: string;
  className?: string;
  mobileRow?: boolean;
  showHeartText?: boolean;
}

export function MakeFavoriteHeartView({
  property,
  onFavorite,
  className,
  containerClassName,
  lovedIconClassName,
  mobileRow = false,
  showHeartText
}: MakeFavoriteHeartViewProps) {
  const [isFavorite, setIsFavorite] = useState(property.isFavorite);
  const [progress, setProgress] = useState(false);

  const posthog = usePostHog();

  const makeFavorite = (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (property.publishId == null) {
      return;
    }

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setProgress(true);

    setPropertyFavoriteStatus({ publishId: property.publishId, isFavorite: !isFavorite })
      .then((response) => {
        if (response) {
          setProgress(false);

          const newFavoriteValue = !isFavorite;
          setIsFavorite(newFavoriteValue);

          if (onFavorite) {
            onFavorite(property, newFavoriteValue);
          }

          if (newFavoriteValue === true) {
            recordUserFavoritedProperty(property, posthog);
            recordPropertySaved(property, posthog);
          }

          if (newFavoriteValue === false) {
            recordPropertyUnsaved(property, posthog);
          }

          const message =
            newFavoriteValue === true
              ? 'Added to Saved Opportunities!'
              : 'Removed from Saved Opportunities!';
          toast.success(message);
        }
      })
      .catch(() => {
        toast.error('Something went wrong, please try again');
      });
  };

  return (
    <div className={classNames('lovedProperty', containerClassName)}>
      {!progress && (
        <span
          onClick={(e) => {
            makeFavorite(e);
          }}
          className={classNames(className, [styles.favProperty], {
            [styles.favProperty]: !mobileRow,
            [styles.favPropertyMobile]: mobileRow,
            [styles.selected]: isFavorite
          })}
        >
          <NotLikedIcon className={lovedIconClassName} />
          {showHeartText && (
            <span className={styles.heartText}>{`${isFavorite ? 'Saved' : 'Save'}`}</span>
          )}
        </span>
      )}
      {progress && <Spinner className="sd-spinner favPropertySpinner" />}
    </div>
  );
}
