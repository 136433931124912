import classNames from 'classnames';
import React from 'react';

import styles from './ProgressTracker.module.scss';
interface UserMenuControlProp {
  stage: number;
  setLength: number;
}

export const ProgressTracker = ({ stage, setLength }: UserMenuControlProp) => {
  return (
    <div className={styles.trackerWrapper}>
      {[...Array(setLength).keys()].map((value, index) => (
        <div
          className={classNames(styles.steps, { [styles.currentStep]: value + 1 === stage })}
          key={`tracker-${index}`}
        >
          {value + 1}
        </div>
      ))}
    </div>
  );
};
