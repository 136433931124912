import classNames from 'classnames';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setPropertyQueryParams } from '../../../actions';
import { StateTerritories } from '../../../api/_base/generated/data-contracts';
import { RegionPropertyCountsType } from '../../../containers/MapViewFeed';
import { getPropertiesViewStatusLabelFromStatuses } from '../../../helpers/marketPlaceViewHelpers';
import { PropertyQueryState } from '../../../store/propertyQuery';
import { ArrowDownIcon, FilterSortIcon } from '../../Icons';

import styles from './MapFilters.module.scss';
import { RenderRegions } from './Regions';
import { StatusFilters } from './StatusFilters';

interface MapFilterProps {
  territories: StateTerritories[];
  territoryTally: RegionPropertyCountsType;
  queryParamsInput: PropertyQueryState;
  viewType: string;
  hidePropertyCounter: boolean;
}

export function MapFilters({
  territories,
  queryParamsInput,
  territoryTally,
  viewType,
  hidePropertyCounter
}: MapFilterProps) {
  const dispatch = useDispatch();
  const mapFilterRef = useRef<HTMLDivElement>(null);
  const [showMapFilter, setShowMapFilter] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParamsInput);
  const [selectedRegion, setSelectedRegion] = useState(queryParams.regionName);

  const closeRegionsMenu = () => {
    setShowMapFilter(false);
  };

  const closeStatusMenu = () => {
    setShowStatus(false);
  };

  const selectRegion = (newRegion: string) => {
    setSelectedRegion(newRegion);
    setShowMapFilter(false);
  };

  const chooseQueryParams = (newParams: PropertyQueryState) => {
    setQueryParams(newParams);
    dispatch(setPropertyQueryParams(newParams));
  };

  useEffect(() => {
    window.addEventListener('click', () => {
      setShowStatus(false);
      setShowMapFilter(false);
    });
  }, []);

  return (
    <div ref={mapFilterRef} className={classNames(styles.filterHeader)}>
      <div className={styles.mobileFilters}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowMapFilter(true);
          }}
          className={'pseudoSelect'}
        >
          {selectedRegion}
        </div>
        {showMapFilter && (
          <div>
            <RenderRegions
              selectedRegion={selectedRegion}
              dropdown={false}
              territories={territories}
              selectRegion={selectRegion}
              closeRegionsMenu={closeRegionsMenu}
              territoryTally={territoryTally}
              chosenStatuses={queryParams.statuses}
              hidePropertyCounter={hidePropertyCounter}
            />
          </div>
        )}
        {showStatus && (
          <StatusFilters
            chooseQueryParams={chooseQueryParams}
            queryParams={queryParams}
            closeStatusMenu={closeStatusMenu}
            viewType={viewType}
            dropdown={false}
          />
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            setShowStatus(true);
          }}
          className={styles.mobileFilterMenuToggle}
        >
          <FilterSortIcon />
        </button>
      </div>

      <div className={classNames(styles.mapFilterContainer)}>
        <div className={styles.mapFilterBackground} />

        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowMapFilter(!showMapFilter);
            setShowStatus(false);
          }}
          className={classNames([styles.mapFilterMenuItem, styles.selectAreaContainer])}
        >
          <div>{selectedRegion}</div>

          <ArrowDownIcon title="Arrow Down" className={styles.mapArrowDown} />
          {showMapFilter && (
            <RenderRegions
              selectedRegion={selectedRegion}
              territories={territories}
              dropdown
              selectRegion={selectRegion}
              closeRegionsMenu={closeRegionsMenu}
              chosenStatuses={queryParams.statuses}
              territoryTally={territoryTally}
              hidePropertyCounter={hidePropertyCounter}
            />
          )}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowMapFilter(false);
            setShowStatus(!showStatus);
          }}
          className={classNames([styles.mapFilterMenuItem])}
        >
          <div>{getPropertiesViewStatusLabelFromStatuses(queryParams.statuses)}</div>
          <ArrowDownIcon title="Arrow Down" className={styles.mapArrowDown} />
          {showStatus && (
            <StatusFilters
              chooseQueryParams={chooseQueryParams}
              queryParams={queryParams}
              closeStatusMenu={closeStatusMenu}
              viewType={viewType}
              dropdown
            />
          )}
        </div>
      </div>
    </div>
  );
}
