import classNames from 'classnames';
import React, { useState } from 'react';

import mapIcon from '../../../../public/images/map_view/map.png';
import satellite from '../../../../public/images/map_view/satellite.png';

import defaultStyles from './MapControls.module.scss';

interface MapControlProp {
  toggleMapControl?: (showHyrid: boolean) => void;
  theme?: typeof defaultStyles;
}

export function MapControls({ toggleMapControl, theme }: MapControlProp) {
  const styles = { ...defaultStyles, ...theme };
  const [isHybrid, setIsHybrid] = useState(false);

  return (
    <>
      <div
        onClick={() => {
          if (toggleMapControl) {
            toggleMapControl(isHybrid);
            setIsHybrid(!isHybrid);
          }
        }}
        className={classNames(styles.toggleHybridView, { [styles.hybrid]: isHybrid })}
      >
        <div>{isHybrid ? 'Map' : 'Satellite'}</div>
        <img src={isHybrid ? mapIcon.src : satellite.src} alt="Map View Icon Toggle" />
      </div>
    </>
  );
}
