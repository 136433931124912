import React from 'react';

import { Tag } from '../Tag';

import styles from './tags.module.scss';

export const TodoTag = ({ ...props }) => {
  return (
    <Tag variant="gray20" {...props} className={styles.sellerTag}>
      TO DO
    </Tag>
  );
};
