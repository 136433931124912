import React from 'react';

import { PROPERTY_HIGHEST_AND_BEST } from '../../helpers/constants';
import { Tag } from '../Tag';

export const HighestAndBestTag = ({ ...props }) => {
  return (
    <Tag variant="blue" {...props}>
      {PROPERTY_HIGHEST_AND_BEST}
    </Tag>
  );
};
