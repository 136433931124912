import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectOfferState } from '../../../store/offers';
import { getCurrencyFormatter } from '../../../utils/formatter.utils';
import { setShowOfferSummary } from '../../../utils/localStorage.utils';
import { ExitIcon, ActivityOpenIcon } from '../../Icons';

import styles from './OfferSummary.module.scss';

const formatCurrency = getCurrencyFormatter();
interface OfferSummaryProps {
  offerSummaryPreference: boolean;
}

export const OfferSummary = ({ offerSummaryPreference }: OfferSummaryProps) => {
  const [summaryPreference, setSummaryPreference] = useState(offerSummaryPreference);
  const [summaryOpen, setSummaryOpen] = useState(offerSummaryPreference);

  const offerState = useSelector(selectOfferState);
  const router = useRouter();

  const toggleOfferSummary = () => {
    setSummaryOpen(!summaryOpen);
  };

  return (
    <div
      className={classNames(styles.offerSummaryContainer, {
        [styles.displayOfferTab]: summaryOpen,
        [styles.hideOfferTab]: !summaryOpen
      })}
    >
      <div className={styles.offerSummaryIwrap}>
        <div onClick={toggleOfferSummary} className={styles.offerSummaryHandle}>
          <div className={styles.offerSummaryClose}>
            <ActivityOpenIcon className={styles.offerSummaryArrow} />
          </div>
        </div>

        <div onClick={toggleOfferSummary} className={styles.offerSummaryCloseModal}>
          <ExitIcon />
        </div>
        <div className={styles.offerSummaryContent}>
          <h6>Active Offers</h6>
          <p>{offerState.count}</p>
          <h6>Total Offer Amount</h6>
          <p>{formatCurrency(offerState.total)}</p>
          <div className={classNames('onlyDesktop', 'pb-2')}>
            <Link href="/dashboard">
              <a>View Your Offers</a>
            </Link>
          </div>

          <div className={styles.doNotShowAgain}>
            <input
              checked={!summaryPreference}
              onChange={() => {
                setSummaryPreference(!summaryPreference);
                setShowOfferSummary(!summaryPreference);
              }}
              type="checkbox"
              id="doNotShowAgain"
            />
            <label htmlFor="doNotShowAgain"> &nbsp;Do not show again</label>
          </div>
          <button
            onClick={() => {
              router.push('/dashboard');
            }}
            className={'notOnDesktop btnPink'}
            type="submit"
            value={'submit'}
          >
            View Your Offers
          </button>
        </div>
      </div>
    </div>
  );
};
