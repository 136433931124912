import React from 'react';

import { PROPERTY_EXTENDED_LISTING } from '../../helpers/constants';
import { Tag } from '../Tag';

export const ExtendedListingTag = ({ ...props }) => {
  return (
    <Tag variant="brown" {...props}>
      {PROPERTY_EXTENDED_LISTING}
    </Tag>
  );
};
