import { To } from 'history';
import React, { ReactNode } from 'react';

interface ConditionalLinkProps {
  to: To;
  style?: React.CSSProperties;
  isLink: boolean;
  children: ReactNode;
  className?: string;
}

export const ConditionalLink = ({
  children,
  to,
  style,
  isLink,
  className
}: ConditionalLinkProps) => {
  return isLink && to ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to.toString()}
      style={style}
      className={className}
    >
      {children}
    </a>
  ) : (
    <div style={style} className={className}>
      {children}
    </div>
  );
};
