import isNumber from 'lodash/isNumber';
import React, { FC, SVGProps } from 'react';

import { ListPropertiesResponseBodyItem } from '../../api/_base/generated/data-contracts';
import { BuyBoxIcon } from '../Icons';
import { Tooltip } from '../design-system';

import styles from './tags.module.scss';

type BuyBoxIconTagProps = SVGProps<SVGSVGElement> & {
  property: Partial<ListPropertiesResponseBodyItem>;
};

export const BuyBoxIconTag: FC<BuyBoxIconTagProps> = ({
  className,
  property: { buyBoxMatch },
  ...rest
}) =>
  isNumber(buyBoxMatch) && buyBoxMatch > 0 ? (
    <Tooltip className={className} content="Matches Buy Box" delayDuration={200}>
      <BuyBoxIcon className={styles.icon} {...rest} />
    </Tooltip>
  ) : null;
