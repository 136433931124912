import React from 'react';

import houseIcon from '../../../../public/icons/house.svg';

import styles from './Loader.module.css';

export const LoaderView = () => (
  <div className={styles.LoaderButton}>
    <img alt="Icon of a house" width="70" src={houseIcon.src} />
  </div>
);
