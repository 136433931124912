import React from 'react';

import { PropertyStatus } from '../../api/_base/generated/data-contracts';
import { ActiveTag, ClosedTag, ComingSoonTag, PendingTag } from '../../components/tags';

import { StatusTagProps } from './PropertyTags';

export const propertyStatusTagMap = {
  [PropertyStatus.ACTIVE]: null, // ActiveTag,
  [PropertyStatus.ALL]: null,
  [PropertyStatus.PENDING]: PendingTag,
  [PropertyStatus.CLOSED]: ClosedTag,
  [PropertyStatus.COMINGSOON]: ComingSoonTag
};

export const PropertyStatusTag = ({
  status,
  ...props
}: { status: PropertyStatus } & StatusTagProps) => {
  const tag = propertyStatusTagMap[status || PropertyStatus.PENDING];

  return tag && React.createElement(tag, props);
};
