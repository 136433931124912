import classNames from 'classnames';
import React from 'react';

import styles from './EmptyImagePlaceholder.module.css';

interface EmptyImagePlaceholderProps {
  height?: string; // @todo - remove this, seems to be unused
  backgroundClassName?: string;
  placeholderText?: string;
  className?: string;
}

export const EmptyImagePlaceholder = ({
  backgroundClassName,
  className,
  placeholderText = 'Images are unavailable'
}: EmptyImagePlaceholderProps) => {
  const backgroundClass = backgroundClassName || styles.defaultBackground;

  return (
    <div className={classNames(backgroundClass, className)}>
      <h2 className={styles.placehoderimg}>{placeholderText}</h2>
    </div>
  );
};
