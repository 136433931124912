import React from 'react';

import { Tag } from '../Tag';

import styles from './tags.module.scss';

export const InProgressTag = ({ ...props }) => {
  return (
    <Tag variant="infoLightBlue" {...props} className={styles.sellerTag}>
      IN PROGRESS
    </Tag>
  );
};
