import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { VALID_SUGGESTED_SUBSCRIPTION_TIERS } from '../../helpers/subscriptionHelper';
import {
  getLastRoute,
  isUserAuthenticated,
  setAttributionUserId,
  setLastRoute,
  setSuggestedMembership
} from '../../utils/localStorage.utils';
import { getCurrentRoute } from '../../utils/url.utils';

export const useLastRouteAuthentication = () => {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const lastRoute = getLastRoute();

  useEffect(() => {
    // Sets last route on page render
    const currentRoute = getCurrentRoute();
    setLastRoute(currentRoute);
  }, []);

  useEffect(() => {
    const authenticated = isUserAuthenticated();
    setIsAuthenticated(authenticated);

    if (router.query.attributionUserId && lastRoute) {
      setAttributionUserId(router.query.attributionUserId as string);
      // remove query params from url to prevent re-triggering modal flow later, should only contain 1 query parameter attributionUserId
      router.replace(lastRoute, lastRoute, { shallow: true });
    }

    if (router.query.membership && lastRoute) {
      const isValidSuggestion = VALID_SUGGESTED_SUBSCRIPTION_TIERS.includes(
        (router.query.membership as string).toLowerCase()
      );

      if (isValidSuggestion) {
        let suggestedMembership = router.query.membership as string;

        if (suggestedMembership === 'edgeplus') {
          suggestedMembership = 'edge+';
        }

        setSuggestedMembership(suggestedMembership);
        // remove query params from url to prevent re-triggering modal flow later, should only contain 1 query parameter suggestedMembership
        router.replace(lastRoute, lastRoute, { shallow: true });
      }
    }

    if (!authenticated) {
      const route = lastRoute ? `/login?next=${getLastRoute()}` : '/login';
      router.push(route);
    }
  }, [router, lastRoute]);

  return isAuthenticated;
};
