import classNames from 'classnames';
import React, { useEffect, useCallback, ReactNode } from 'react';

import styles from './FullScreenContent.module.scss';

interface FullScreenContentProps {
  escFunction?: (e: Event) => void;
  onClickOutside?: () => void;
  children: ReactNode;
}

export function FullScreenContent({
  escFunction,
  onClickOutside,
  children
}: FullScreenContentProps) {
  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      // TODO: Note this is now deprecated https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
      if (event.keyCode === 27) {
        if (escFunction) escFunction(event);
      }
    },
    [escFunction]
  );

  useEffect(() => {
    document.addEventListener('keydown', onEscape, false);

    return () => {
      document.removeEventListener('keydown', onEscape, false);
    };
  }, [onEscape]);

  return (
    <div
      className={classNames(styles.fullscreen, styles.backdrop)}
      onClick={() => (onClickOutside ? onClickOutside() : null)}
    >
      {children}
    </div>
  );
}
