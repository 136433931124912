import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /api/property/${propertyId}/support
export const getPropertySupportUser = (propertyId: string) => {
  return api.propertyControllerGetPropertySupportUser(propertyId);
};

export const usePropertySupportUser = (propertyId: string) => {
  return useQuery({
    queryKey: ['getPropertySupportUser'],
    queryFn: () => getPropertySupportUser(propertyId)
  });
};
