import React from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';

import { PropertyDetailsResponseBody } from '../../../api/_base/generated/data-contracts';
import { Image } from '../../../types/propertyTypes';
import { DynamicImageGallery } from '../../DynamicImageGallery/DynamicImageGallery';
import { EmptyImagePlaceholder } from '../../EmptyImagePlaceholder';

interface ImageSliderProps {
  showThumbnails?: boolean;
  property: PropertyDetailsResponseBody;
  showFullscreenButton?: boolean;
  showBullets?: boolean;
  placeHolderClassName?: string;
}

export function ImageSlider({
  showThumbnails,
  property,
  showFullscreenButton,
  showBullets,
  placeHolderClassName
}: ImageSliderProps) {
  const featuredImage = property.images
    ? // @ts-ignore TODO: fix, field `sharinPixSfId` does not exits
      property.images.filter((e: Image) => e.publishId === property.sharinPixSfId)
    : [];
  const restOfTheImages = property.images
    ? // @ts-ignore TODO: fix, field `sharinPixSfId` does not exits
      property.images.filter((e: Image) => e.publishId !== property.sharinPixSfId)
    : [];
  const imageArray = [...featuredImage, ...restOfTheImages];

  const images: ReadonlyArray<ReactImageGalleryItem> = imageArray.map((image) => ({
    original: image.image || '',
    thumbnail: image.imageThumb || '',
    altText: '',
    caption: '',
    type: 'image'
  }));

  // renderLeftNav and renderRightNav appear to be the same svgs as provided in the library
  // they are passed because there is no good way to override the click event and stop propagation
  // we should move off this library when we have time
  return (
    <>
      {!!images.length && (
        <DynamicImageGallery
          useBrowserFullscreen={false}
          showThumbnails={showThumbnails}
          showPlayButton={false}
          showFullscreenButton={showFullscreenButton}
          items={images}
          lazyLoad={true}
          showIndex={true}
          showBullets={showBullets || false}
          renderLeftNav={(onClick, disabled) => {
            return (
              <button
                className="image-gallery-icon image-gallery-left-nav"
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <svg
                  className="image-gallery-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="6 0 12 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </button>
            );
          }}
          renderRightNav={(onClick, disabled) => {
            return (
              <button
                className="image-gallery-icon image-gallery-right-nav"
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <svg
                  className="image-gallery-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="6 0 12 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            );
          }}
        />
      )}
      {!images.length && <EmptyImagePlaceholder className={placeHolderClassName} />}
    </>
  );
}
