import React from 'react';

import { PROPERTY_STANDARD_AUCTION } from '../../helpers/constants';
import { Tag } from '../Tag';

export const StandardAuctionTag = ({ ...props }) => {
  return (
    <Tag variant="green" {...props}>
      {PROPERTY_STANDARD_AUCTION}
    </Tag>
  );
};
