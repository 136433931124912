import { useEffect, useState } from 'react';

import { isHomeownerAuthenticated, isUserAuthenticated } from '../../utils/localStorage.utils';

export const HOMEOWNER_EXPERIENCE_MODE = 'HOMEOWNER';
export const INVESTOR_EXPERIENCE_MODE = 'INVESTOR';

export type ExperienceModeType = typeof HOMEOWNER_EXPERIENCE_MODE | typeof INVESTOR_EXPERIENCE_MODE;

export const authenticationCheckMethods = {
  [HOMEOWNER_EXPERIENCE_MODE]: isHomeownerAuthenticated,
  [INVESTOR_EXPERIENCE_MODE]: isUserAuthenticated
};

export const authenticationModeHelper = (mode?: ExperienceModeType) => {
  const defaultMode = mode || INVESTOR_EXPERIENCE_MODE;

  return authenticationCheckMethods[defaultMode];
};

export const useIsUserAuthenticated = (mode?: ExperienceModeType) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    setIsAuthenticated(authenticationModeHelper(mode)());
  }, [mode]);

  return isAuthenticated;
};
