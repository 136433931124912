import { isEqual } from 'lodash';

import { PropertyStatus, SortOrder } from '../api/_base/generated/data-contracts';

export const mapStyles = [
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [{ visibility: 'on' }]
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'landscape.natural',
    elementType: 'all',
    stylers: [{ visibility: 'off' }]
  }
];

export const propertiesViewSortFields = [
  {
    sortKey: 'propertyStatus',
    displayName: 'STATUS',
    leftAlign: true,
    mobileSort: 0,
    size: 'full',
    normalSize: true
  },
  {
    sortKey: 'price',
    displayName: 'ASKING PRICE',
    mobileHide: false,
    mobileDisplay: 'Asking Price',
    size: 'large',
    normalSize: true
  },
  {
    sortKey: 'projectedCloseDate',
    displayName: 'ENDS',
    mobileSortMenu: false,
    mobileSort: 1,
    mobileRangeHigh: 'Latest',
    mobileRangeLow: 'Earliest',
    mobileDisplay: 'Offer Due',
    size: 'full',
    normalSize: true,
    initialSortOrder: SortOrder.ASC
  },
  {
    sortKey: 'closeOfEscrow',
    displayName: 'EST COE',
    mobileHide: true,
    hiddenTablet: true,
    mobileSortMenu: true,
    mobileSort: 0,
    size: 'full',
    normalSize: true
  },
  {
    sortKey: 'bedrooms',
    displayName: 'BD',
    mobileHide: true,
    mobileSort: 3,
    mobileDisplay: 'Bed',
    size: 'small',
    normalSize: true
  },
  {
    sortKey: 'bathrooms',
    displayName: 'BA',
    mobileHide: true,
    mobileSort: 4,
    mobileDisplay: 'Bath',
    size: 'small',
    normalSize: true
  },
  {
    sortKey: 'squareFeet',
    displayName: 'SQ FT',
    mobileHide: true,
    mobileSort: 2,
    mobileDisplay: 'Sq Ft',
    size: 'medium',
    normalSize: true
  },
  {
    sortKey: 'yearBuilt',
    displayName: 'BUILT',
    mobileHide: true,
    hiddenTablet: true,
    mobileSort: 0,
    size: 'medium',
    normalSize: true
  }
];
type PropertiesViewStatusFilterType = {
  label: string;
  value: PropertyStatus[];
};
export const propertiesViewStatusFilters: PropertiesViewStatusFilterType[] = [
  { label: 'Active', value: [PropertyStatus.ACTIVE] },
  { label: 'Coming Soon', value: [PropertyStatus.COMINGSOON] },
  { label: 'Pending', value: [PropertyStatus.PENDING] },
  {
    label: 'Historical Sales',
    value: [PropertyStatus.CLOSED]
  },
  {
    label: 'All',
    value: [
      PropertyStatus.ACTIVE,
      PropertyStatus.COMINGSOON,
      PropertyStatus.PENDING,
      PropertyStatus.CLOSED
    ]
  }
];

export const getPropertiesViewStatusLabelFromStatuses = (
  statuses: string[]
): string | undefined => {
  return propertiesViewStatusFilters.find(({ value }) => isEqual(statuses, value))?.label;
};
