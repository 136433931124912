import { MapPosition } from '../store/mapPosition';
import { OfferState } from '../store/offers';
import { PropertySortOrder, PropertyQueryState, Region } from '../store/propertyQuery';

import {
  SET_OFFERS,
  SET_PROPERTY_ORDER,
  SET_PROPERTY_PAGE,
  SET_PROPERTY_QUERY,
  SET_PROPERTY_REGION,
  SET_MAP_POSITION
} from './types';

export interface SetOfferAction {
  type: typeof SET_OFFERS;
  payload: OfferState;
}

export function setOffers(offerSummary: OfferState): SetOfferAction {
  return {
    type: SET_OFFERS,
    payload: offerSummary
  };
}

export function setOffersSummary(offerSummary: OfferState) {
  return {
    type: SET_OFFERS,
    payload: offerSummary
  };
}

export function setPropertySortOrder(sorting: PropertySortOrder) {
  return {
    type: SET_PROPERTY_ORDER,
    payload: sorting
  };
}

export function setPropertyPage(page: number) {
  return {
    type: SET_PROPERTY_PAGE,
    payload: page
  };
}

export function setPropertyQueryParams(queryParams: PropertyQueryState) {
  return {
    type: SET_PROPERTY_QUERY,
    payload: queryParams
  };
}

export function setPropertyRegion(region: Region) {
  return {
    type: SET_PROPERTY_REGION,
    payload: region
  };
}

export function setMapPosition(mapPosition: MapPosition) {
  return {
    type: SET_MAP_POSITION,
    payload: mapPosition
  };
}
