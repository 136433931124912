import React from 'react';

import { PropertyStatus } from '../../api/_base/generated/data-contracts';
import { Tag } from '../Tag';

export const ComingSoonTag = ({ ...props }) => {
  return (
    <Tag variant="yellow" {...props}>
      {PropertyStatus.COMINGSOON}
    </Tag>
  );
};
