import React, { ReactNode } from 'react';

import { PropertyStatus } from '../../api/_base/generated/data-contracts';
import { TagProps } from '../Tag';

import { ExtendedListingTag } from './ExtendedListingTag';
import { HighestAndBestTag } from './HighestAndBestTag';
import { PropertyStatusTag } from './PropertyStatusTag';
import { StandardAuctionTag } from './StandardAuctionTag';
import styles from './tags.module.scss';

export type StatusTagProps = TagProps;

interface PropertyTagsProps {
  status: PropertyStatus;
  isExtendedListing?: boolean | null;
  isStandardAuctionProperty?: boolean | null;
  isHighestAndBestProperty?: boolean | null;
  children?: ReactNode;
}

export const PropertyTags = ({
  status,
  isExtendedListing,
  isStandardAuctionProperty,
  isHighestAndBestProperty,
  children,
  ...props
}: PropertyTagsProps & StatusTagProps) => {
  const isActiveProperty = status === PropertyStatus.ACTIVE;

  return (
    <div className={styles.group}>
      <PropertyStatusTag status={status} {...props} />
      {isStandardAuctionProperty && isActiveProperty && <StandardAuctionTag {...props} />}
      {isHighestAndBestProperty && isActiveProperty && <HighestAndBestTag {...props} />}
      {isExtendedListing && isActiveProperty && <ExtendedListingTag {...props} />}
      {children}
    </div>
  );
};
