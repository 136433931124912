import classNames from 'classnames';
import { differenceInHours, isBefore } from 'date-fns';
import React, { useCallback, useMemo } from 'react';

import {
  ListPropertiesResponseBodyItem,
  ListingListWithOfferEntryApiModel,
  PropertyStatus
} from '../../../api/_base/generated/data-contracts';
import { EmptyColumnValue } from '../../../helpers/constants';
import { squareFeetFormattedText } from '../../../utils/common.utils';
import { convertToNoonDate, formatDate } from '../../../utils/dateTime.utils';
import { formatNumToCurrency, trimString } from '../../../utils/formatter.utils';
import { OfferDueCountdown } from '../../OfferDueCountdown';
import { BuyBoxIconTag } from '../../tags/BuyBoxIconTag';
import { PropertyTags } from '../../tags/PropertyTags';
import { MakeFavoriteHeartView } from '../MakeFavoriteHeartView/MakeFavoriteHeartView';

import styles from './PropertyRow.module.scss';
interface PropertyRowProps {
  property: ListPropertiesResponseBodyItem;
  onHover: (p: ListPropertiesResponseBodyItem) => void;
  onClick: (p: ListPropertiesResponseBodyItem) => void;
  onFavProperty: (
    p: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel,
    f: boolean
  ) => void;
  isTagVisible?: boolean;
}

const MAX_ADDRESS_LENGTH = 25;

interface PropertyRowCountdownProps {
  projectedCloseDate: string;
}

export const PropertyRowCountdown = ({ projectedCloseDate }: PropertyRowCountdownProps) => {
  const closeDate = useMemo(() => convertToNoonDate(projectedCloseDate), [projectedCloseDate]);
  const currentTime = new Date();
  const showTimer =
    !isBefore(closeDate, currentTime) && differenceInHours(closeDate, new Date()) <= 23;

  return (
    <>
      {showTimer ? (
        <OfferDueCountdown
          propertyStatus={PropertyStatus.ACTIVE}
          projectedCloseDate={projectedCloseDate}
          condensed
        />
      ) : (
        <div>{formatDate(projectedCloseDate, undefined, undefined, 'L/d/yyyy')}</div>
      )}

      <div className={styles.closeTime}>12 PM PDT</div>
    </>
  );
};

export function PropertyRow({ property, onHover, onClick, onFavProperty }: PropertyRowProps) {
  const handleFavProperty = useCallback(
    (
      property: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel,
      favorite: boolean
    ) => {
      if (onFavProperty) onFavProperty(property, favorite);
    },
    [onFavProperty]
  );

  // default to "Pending" status if none is available
  const propertyStatus = property.propertyStatus || PropertyStatus.PENDING;
  const streetAddressDisplayText =
    propertyStatus !== PropertyStatus.COMINGSOON &&
    trimString(property.streetAddress || '', MAX_ADDRESS_LENGTH);

  const showAskingText = property.isStandardAuctionProperty || property.isHighestAndBestProperty;

  return (
    <div className={styles.rowb}>
      <div className={styles.mobileRowContent}>
        <BuyBoxIconTag className={styles.buyBoxIcon} property={property} />
        <MakeFavoriteHeartView
          className={styles.mobileRowHeart}
          mobileRow={true}
          onFavorite={(
            property: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel,
            favorite
          ) => handleFavProperty(property, favorite)}
          property={property}
          containerClassName={styles.heartIcon}
        />
        <div className={styles.mobileEndsDateDisplay}>
          {![PropertyStatus.CLOSED, PropertyStatus.PENDING].includes(propertyStatus) &&
            property.projectedCloseDate && (
              <>
                <div className={styles.offerDueText}>Offer Due</div>
                <PropertyRowCountdown projectedCloseDate={property.projectedCloseDate} />
              </>
            )}
          {!property.projectedCloseDate && EmptyColumnValue}
        </div>
      </div>
      {propertyStatus === PropertyStatus.ACTIVE && (
        <div className={styles.mobilePrice}>
          {property.price ? (
            <>
              {formatNumToCurrency(property.price)}
              {showAskingText && <div className={styles.mobilePriceInfo}>Asking</div>}
            </>
          ) : (
            EmptyColumnValue
          )}
        </div>
      )}

      <div
        onClick={() => onClick && onClick(property)}
        onMouseEnter={() => onHover && onHover(property)}
        className={styles.address}
      >
        <div className={styles.tag}>
          <PropertyTags
            status={propertyStatus}
            isExtendedListing={property.isExtendedListing}
            isStandardAuctionProperty={property.isStandardAuctionProperty}
            isHighestAndBestProperty={property.isHighestAndBestProperty}
            rounded
            small
          />
        </div>
        {property.streetAddress ? (
          <>
            {streetAddressDisplayText}
            <div className={styles.cityStateZip}>
              {property.city}, {property.state} {property.zipCode}
            </div>
          </>
        ) : (
          EmptyColumnValue
        )}
      </div>

      <div
        className={classNames(styles.propertyRow)}
        onClick={() => onClick && onClick(property)}
        onMouseEnter={() => onHover && onHover(property)}
      >
        <div className={classNames(styles.propertyColumnLarge, 'onlyDesktop', styles.priceRow)}>
          <div>
            {propertyStatus === PropertyStatus.ACTIVE ? (
              <>
                {property.price ? formatNumToCurrency(property.price) : EmptyColumnValue}
                {showAskingText && <div className={styles.priceInfo}>Asking</div>}
              </>
            ) : (
              EmptyColumnValue
            )}
          </div>
        </div>
        <div className={classNames(styles.propertyColumn, 'onlyDesktop')}>
          <div>
            {(propertyStatus === PropertyStatus.ACTIVE && property.projectedCloseDate && (
              <div>
                {property.projectedCloseDate ? (
                  <PropertyRowCountdown projectedCloseDate={property.projectedCloseDate} />
                ) : (
                  EmptyColumnValue
                )}
              </div>
            )) ||
              EmptyColumnValue}
          </div>
        </div>
        <div className={classNames(styles.propertyColumn, 'onlyDesktop', styles.hiddenTablet)}>
          <div>
            {(propertyStatus === PropertyStatus.ACTIVE && property.closeOfEscrow && (
              <div>{formatDate(property.closeOfEscrow, undefined, undefined, 'L/d/yy')}</div>
            )) ||
              EmptyColumnValue}
          </div>
        </div>

        <div className={styles.propertyColumnSmall}>
          <div>
            {property.bedrooms ? (
              <>
                {property.bedrooms} <span className="notOnDesktop">&nbsp;BD</span>
              </>
            ) : (
              <>
                {EmptyColumnValue} <span className="notOnDesktop">&nbsp;BD</span>
              </>
            )}
          </div>
        </div>
        <div className={styles.propertyColumnSmall}>
          <div>
            {property.bathrooms ? (
              <>
                {property.bathrooms} <span className="notOnDesktop">&nbsp;BR</span>
              </>
            ) : (
              <>
                {EmptyColumnValue} <span className="notOnDesktop">&nbsp;BR</span>
              </>
            )}
          </div>
        </div>
        <div className={classNames(styles.propertyColumnMedium, styles.endColMobile)}>
          <div>
            {property.squareFeet ? (
              <>
                {squareFeetFormattedText(property.squareFeet)}
                <span className="notOnDesktop">&nbsp;SQ FT</span>
              </>
            ) : (
              <>
                {EmptyColumnValue} <span className="notOnDesktop">&nbsp;SQ FT</span>
              </>
            )}
          </div>
        </div>
        <div
          className={classNames(styles.hiddenTablet, styles.propertyColumnMedium, 'onlyDesktop')}
        >
          <div>{property.yearBuilt !== 0 ? property.yearBuilt : EmptyColumnValue}</div>
        </div>
        <div
          className={classNames(styles.hiddenTablet, styles.propertyColumnMedium, 'onlyDesktop')}
        >
          <div>
            <span className={styles.hrt}>
              <BuyBoxIconTag className={styles.buyBoxIcon} property={property} />
              <MakeFavoriteHeartView
                onFavorite={(property, favorite) => handleFavProperty(property, favorite)}
                property={property}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
