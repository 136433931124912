import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHasMembership } from '../api/user/getHasMembership';
import { AppDispatch } from '../store';
import { selectHasMembership, setHasMembership } from '../store/misc';

import { useIsUserAuthenticated } from './localStorage';

export const useHasMembership = () => {
  const dispatch = useDispatch<AppDispatch>();
  const hasMembershipFromStore = useSelector(selectHasMembership);
  const isAuthenticated = useIsUserAuthenticated();

  const hasMembershipRef = useRef<boolean | null>(null);
  const [hasMembership, setHasMembershipState] = useState(hasMembershipRef.current);

  useEffect(() => {
    const getMembership = async () => {
      const hasMembershipResponse = await getHasMembership();
      const hasMembershipResult = hasMembershipResponse.data?.hasMembership;
      dispatch(setHasMembership(hasMembershipResult));
    };

    if (hasMembershipFromStore !== undefined) {
      // If the membership info is already available in the store, use it
      setHasMembershipState(hasMembershipFromStore);
    } else if (isAuthenticated && hasMembershipRef.current === null) {
      // If not authenticated or already fetched, do nothing
      getMembership();
    }
  }, [dispatch, isAuthenticated, hasMembershipFromStore]);

  return { hasMembership };
};
