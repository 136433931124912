import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setMapPosition, setPropertyRegion } from '../../../actions';
import {
  PropertyStatus,
  StateTerritories,
  TerritoryWithPropertyCounts
} from '../../../api/_base/generated/data-contracts';
import { RegionPropertyCountsType } from '../../../containers/MapViewFeed';
import { AppDispatch } from '../../../store';
import { ExitIcon } from '../../Icons';

import styles from './MapFilters.module.scss';
export interface RegionProps {
  selectRegion: (filter: string) => void;
  selectedRegion: string;
  dropdown: boolean;
  closeRegionsMenu: () => void;
  territories: StateTerritories[];
  territoryTally: RegionPropertyCountsType;
  chosenStatuses: string[];
  hidePropertyCounter: boolean;
}

export const RenderRegions = ({
  selectRegion,
  selectedRegion,
  dropdown,
  closeRegionsMenu,
  territories,
  territoryTally,
  chosenStatuses,
  hidePropertyCounter
}: RegionProps) => {
  const [regionName, setRegionName] = useState(selectedRegion);
  const [preSelectedRegion, setPreSelectedRegion] = useState<TerritoryWithPropertyCounts>();

  const dispatch = useDispatch<AppDispatch>();

  const chooseRegion = (region: TerritoryWithPropertyCounts) => {
    selectRegion(region.name!);
    dispatch(
      setMapPosition({
        lat: region.latitude!,
        long: region.longitude!,
        zoom: region.name === 'All' ? 4.25 : 9
      })
    );
    dispatch(setPropertyRegion({ territoryId: region.territoryId!, regionName: region.name! }));
  };

  const preSelectRegion = (region: TerritoryWithPropertyCounts) => {
    setRegionName(region.name!);
    setPreSelectedRegion(region);
  };

  const getTotalTerritoryTally = (territoryId: string): number => {
    if (!territoryTally[territoryId]) return 0;

    return (
      (chosenStatuses.includes(PropertyStatus.ACTIVE)
        ? territoryTally[territoryId].numberOfActiveProperties
        : 0) +
      (chosenStatuses.includes(PropertyStatus.PENDING)
        ? territoryTally[territoryId].numberOfPendingProperties
        : 0) +
      (chosenStatuses.includes(PropertyStatus.COMINGSOON)
        ? territoryTally[territoryId].numberOfComingSoonProperties
        : 0)
    );
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classNames({
        [styles.subMenuSelect]: dropdown,
        [styles.mobileSelect]: !dropdown
      })}
    >
      {!dropdown && (
        <>
          <h3>Regions</h3>
          <ExitIcon onClick={closeRegionsMenu} className="closeModalIcon" />
        </>
      )}

      {territories && territories.length > 0 && (
        <div className={styles.regionScrollWrapMobile}>
          {territories
            .filter((territory) => territory.name !== null)
            .map((territory, index) => (
              <div key={`${territory.name}${index}`}>
                {territory && territory.name !== 'All' && (
                  <div className={styles.state}>{territory.name.toLocaleUpperCase()}</div>
                )}

                {territory.regions.map((region, rIndex) => (
                  <div
                    key={`territory-name-desktop-${rIndex}`}
                    onClick={() => {
                      if (region.isActive && !region.isComingSoon) {
                        dropdown ? chooseRegion(region) : preSelectRegion(region);
                      }
                    }}
                    className={classNames([
                      styles.region,
                      {
                        [styles.selectableMenuOption]: !region.isComingSoon,
                        [styles.selectedMenuOption]: regionName === region.name,
                        [styles.comingSoon]: region.isComingSoon
                      }
                    ])}
                  >
                    <input
                      className={styles.regionInputRadio}
                      type="radio"
                      id={`region_name_radio_${rIndex}_${index}`}
                      checked={regionName === region.name}
                      value={region.name}
                      name="regionSelect"
                      readOnly
                    />
                    <label htmlFor={`region_name_radio_${rIndex}_${index}`}>
                      {region.name}
                      {hidePropertyCounter
                        ? ''
                        : ` (${getTotalTerritoryTally(region.territoryId!)})`}
                    </label>
                    {region.isComingSoon && (
                      <div className={styles.comingSoonText}>
                        COMING
                        <br />
                        SOON!
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      )}

      {!dropdown && (
        <button
          onClick={() => {
            if (preSelectedRegion) {
              chooseRegion(preSelectedRegion);
            } else {
              closeRegionsMenu();
            }
          }}
          className={'btnPink mt-4 mr-4'}
          type="submit"
          value={'submit'}
        >
          See Houses
        </button>
      )}
    </div>
  );
};
