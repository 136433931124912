import classNames from 'classnames';
import { isEqual } from 'lodash';
import React, { useState, MouseEvent } from 'react';

import { SortOrder } from '../../../api/_base/generated/data-contracts';
import {
  propertiesViewSortFields,
  propertiesViewStatusFilters
} from '../../../helpers/marketPlaceViewHelpers';
import { PropertyQueryState } from '../../../store/propertyQuery';
import { ExitIcon } from '../../Icons';

import styles from './MapFilters.module.scss';

export interface StatusFilterProps {
  dropdown: boolean;
  closeStatusMenu: () => void;
  chooseQueryParams: (queryParams: PropertyQueryState) => void;
  queryParams: PropertyQueryState;
  viewType: string;
}

export const StatusFilters = ({
  dropdown,
  closeStatusMenu,
  chooseQueryParams,
  queryParams,
  viewType
}: StatusFilterProps) => {
  const sortFields = propertiesViewSortFields.filter((sort) => {
    return sort.mobileSort !== undefined && sort.mobileSort > 0;
  });

  const [preQueryParams, setPreQueryParams] = useState({ ...queryParams });

  const queryChange = () => {
    chooseQueryParams({ ...preQueryParams });
  };

  const handleStatusOptions =
    (dropdown: boolean, value: string[]) => (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (dropdown) {
        chooseQueryParams({ ...queryParams, statuses: value });
        closeStatusMenu();
      } else {
        setPreQueryParams({ ...preQueryParams, statuses: value });
      }
    };

  const handleSortOptions =
    (sortKey: string, sortOder: SortOrder) => (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      setPreQueryParams({
        ...preQueryParams,
        sortBy: sortKey,
        sortOrder: sortOder
      });
    };

  return (
    <div
      className={classNames({
        [styles.subMenuSelect]: dropdown,
        [styles.mobileSelect]: !dropdown
      })}
    >
      {!dropdown && (
        <div>
          <h3>Categories</h3>
          <ExitIcon onClick={closeStatusMenu} className="closeModalIcon" />
        </div>
      )}
      <div
        className={classNames({
          [styles.statusOptions]: !dropdown
        })}
      >
        {!dropdown && (
          <>
            <div className={styles.state}>FILTER BY STATUS</div>
          </>
        )}
        {propertiesViewStatusFilters.map((filter, index) => (
          <div
            onClick={handleStatusOptions(dropdown, filter.value)}
            className={classNames([
              styles.region,
              styles.selectableMenuOption,
              { [styles.selectedMenuOption]: isEqual(filter.value, preQueryParams.statuses) }
            ])}
            key={filter.label}
          >
            <input
              className={styles.regionInputRadio}
              type="radio"
              id={`statusSelect-${index}`}
              value={filter.value}
              name="selectStatus"
            />
            <label htmlFor={`statusSelect-${index}`}>
              <span>{filter.label}</span>
            </label>
          </div>
        ))}
        {!dropdown && viewType === 'table' && (
          <>
            <div className={styles.state}>SORT OPTIONS</div>
            {sortFields.map((field, index) => {
              return (
                <span key={`sortField${index}`}>
                  <div
                    onClick={handleSortOptions(field.sortKey, SortOrder.DESC)}
                    className={classNames([
                      styles.region,
                      styles.selectableMenuOption,
                      {
                        [styles.selectedMenuOption]:
                          field.sortKey === preQueryParams.sortBy &&
                          preQueryParams.sortOrder === SortOrder.DESC
                      }
                    ])}
                  >
                    <input
                      className={styles.regionInputRadio}
                      type="radio"
                      id={`sort-filter-desc-${index}`}
                      value={field.displayName}
                      name="selectFilterSort"
                    />
                    <label htmlFor={`sort-filter-desc-${index}`}>
                      {field.mobileDisplay} (
                      {field.mobileRangeHigh ? field.mobileRangeHigh : 'High'} -
                      {field.mobileRangeLow ? field.mobileRangeLow : 'Low'})
                    </label>
                  </div>

                  <div
                    onClick={handleSortOptions(field.sortKey, SortOrder.ASC)}
                    className={classNames([
                      styles.region,
                      styles.selectableMenuOption,
                      {
                        [styles.selectedMenuOption]:
                          field.sortKey === preQueryParams.sortBy &&
                          preQueryParams.sortOrder === SortOrder.ASC
                      }
                    ])}
                  >
                    <input
                      className={styles.regionInputRadio}
                      type="radio"
                      id={`sort-filter-asc-${index}`}
                      value={field.displayName}
                      name="selectFilterSort"
                    />
                    <label htmlFor={`sort-filter-asc-${index}`}>
                      {field.mobileDisplay} ({field.mobileRangeLow ? field.mobileRangeLow : 'Low'} -
                      {field.mobileRangeHigh ? field.mobileRangeHigh : 'High'})
                    </label>
                  </div>
                </span>
              );
            })}
          </>
        )}
      </div>

      {!dropdown && (
        <div>
          <button
            onClick={() => {
              queryChange();
            }}
            className={'btnPink mt-4 mr-4'}
            type="submit"
            value={'submit'}
          >
            Confirm
          </button>
        </div>
      )}
    </div>
  );
};
