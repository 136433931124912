import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /api/user/has-membership
export const getHasMembership = () => {
  return api.userControllerHasMembership();
};

export const useHasMembership = () => {
  return useQuery({ queryKey: ['getHasMembership'], queryFn: getHasMembership });
};
