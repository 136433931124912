import classNames from 'classnames';
import React from 'react';
import NumberFormat from 'react-number-format';

import {
  PropertyDetailsResponseBody,
  PropertyStatus
} from '../../../api/_base/generated/data-contracts';
import { EmptyColumnValue } from '../../../helpers/constants';
import { formatDate } from '../../../utils/dateTime.utils';
import { ConditionalLink } from '../../ContitionalLink';
import { BuyBoxIconTag } from '../../tags/BuyBoxIconTag';
import { MakeFavoriteHeartView } from '../MakeFavoriteHeartView/MakeFavoriteHeartView';

import styles from './PropertyCard.module.scss';
interface PropertyCardProps {
  property: PropertyDetailsResponseBody;
}

export function PropertyCard({ property }: PropertyCardProps) {
  const style = {
    display: 'block',
    margin: '20px auto 10px',
    marginBottom: '50px',
    maxWidth: '550px'
  };

  const offerDueIn = () => {
    return property.projectedCloseDate && property.projectedCloseDate.length
      ? `Offer due ${formatDate(
          property.projectedCloseDate,
          undefined,
          undefined,
          'L/d/yyyy'
        )} 12:00pm PST`
      : '';
  };

  return (
    <ConditionalLink
      to={'/feed/' + property.id}
      style={style}
      isLink={property.propertyStatus !== PropertyStatus.CLOSED}
    >
      <div className={classNames(styles.propertyCard, 'sshadow')}>
        <span
          className={'overlaylabel PROPERTY_STAGE ' + property.propertyStatus?.replace(' ', '_')}
        >
          {property.propertyStatus?.replace('_', ' ')}
        </span>

        <div className="image">
          <div
            style={{ width: '100%', height: '250px' }}
            className={classNames('animatedBackground', styles.pimg)}
          >
            <img
              alt=""
              style={{ objectFit: 'cover' }}
              width="100%"
              height="250"
              className={styles.pimg}
              src={property.image || undefined}
            />
          </div>
        </div>

        <div className="content">
          <>
            <div className="title mb-2">
              {property.propertyStatus !== PropertyStatus.CLOSED && (
                <span className="price">
                  <NumberFormat
                    value={property.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </span>
              )}
              {![PropertyStatus.CLOSED, PropertyStatus.PENDING].includes(
                property.propertyStatus!
              ) &&
                property.projectedCloseDate && <span>{offerDueIn()}</span>}

              <BuyBoxIconTag property={property} />
              {property.propertyStatus !== PropertyStatus.CLOSED && (
                <MakeFavoriteHeartView property={property} />
              )}
            </div>
            <div className="feature" style={{ display: 'inline-block' }}>
              <label className="pbedbathlabel m-0">
                {property.bedrooms} bd<span className="pdivider">|</span>
                {property.bathrooms} ba<span className="pdivider">|</span>
                {property.squareFeet} sqft<span className="pdivider">|</span>
                {property.yearBuilt} built
              </label>
            </div>

            <div className="address mb-2">
              {property.propertyStatus !== PropertyStatus.CLOSED && (
                <span>{property.streetAddress}, </span>
              )}
              <span>
                {property.city}, {property.state} {property.zipCode}
              </span>
              {![PropertyStatus.CLOSED, PropertyStatus.PENDING].includes(
                property.propertyStatus!
              ) &&
                property.projectedCloseDate &&
                property.closeOfEscrow && (
                  <div>
                    <span>
                      COE :
                      {property.projectedCloseDate
                        ? formatDate(property.projectedCloseDate, undefined, undefined, 'L/d/yyyy')
                        : EmptyColumnValue}
                    </span>
                  </div>
                )}
            </div>
          </>
        </div>
      </div>
    </ConditionalLink>
  );
}
