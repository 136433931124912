import { useMutation } from '@tanstack/react-query';

import { api } from '../_base';
import { CreatePropertyOfferRequestBody } from '../_base/generated/data-contracts';

// POST /api/property/offer
export const createPropertyOffer = (body: CreatePropertyOfferRequestBody) => {
  return api.propertyControllerCreatePropertyOffer(body);
};

export const useCreatePropertyOffer = () => {
  return useMutation({ mutationFn: createPropertyOffer });
};
